<template>
    <div class="about">
      <overlay-component :isLoading="is_Loading" />
      <b-card-text>
        <div
          class="editable-div"
          @input="handleInput"
          ref="editableDiv"
          :contenteditable="isEdit"
        >
          {{
            aboutProps
              ? aboutProps
              : $t("g.ThereIsCurrentlyNoInformationAboutThisMember")
          }}
        </div>
      </b-card-text>
      <p v-if="error" class="error-text">
        {{ $t("g.profile/about/error_text") }}
      </p>
      <b-button v-if="isEdit" variant="primary" @click="validate">
        {{ $t("g.profile_about_save") }}
      </b-button>
    </div>
  </template>
    
  <script>
  import OverlayComponent from "@/components/shared/OverlayComponent";
  import { BCardText, BFormInput, BButton } from "bootstrap-vue";
  
  export default {
    components: {
      BCardText,
      BFormInput,
      BButton,
      OverlayComponent,
    },
    props: ["aboutProps", "editable", "isEdit"],
    data() {
      return {
        is_Loading: false,
        content: null,
        error: false,
      };
    },
    methods: {
      makeToast(variant = null, body, title) {
        this.$bvToast.toast(this.$t(body), {
          title: this.$t(title),
          variant,
          solid: true,
        });
      },
      handleInput() {
        this.error = false;
        this.content = this.$refs.editableDiv.innerText;
      },
      async editAboutHandler() {
        try {
          this.is_Loading = true;
          const formdata = new FormData();
          formdata.append("about", this.content);
          await this.$http.post(
            "/auth/teacher/edit-profile?_method=put",
            formdata
          );
          this.isEdit = false;
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
        } catch (error) {
          for (const key in error.response?.data?.errors) {
            this.makeToast(
              "danger",
              error.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
        } finally {
          this.is_Loading = false;
        }
      },
      validate() {
        if (this.content) {
          editAboutHandler();
        } else {
          this.error = true;
          this.$refs.editableDiv.focus();
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  @import "./index.scss";
  </style>
    